// Import all SVG patterns
import xDec231881301 from 'assets/mapStyles/fillPatterns/x-Dec.23-1881.30-1.svg';
import xDec211881141 from 'assets/mapStyles/fillPatterns/x-Dec.21-1881.14-1.svg';
import xDec068800777 from 'assets/mapStyles/fillPatterns/x-Dec.06-1880.07-7.svg';
import xDec038811123 from 'assets/mapStyles/fillPatterns/x-Dec.03-1881.12-3.svg';
import xDec038811122 from 'assets/mapStyles/fillPatterns/x-Dec.03-1881.12-2.svg';
import xDec028862452 from 'assets/mapStyles/fillPatterns/x-Dec.02-1886.24-5.svg';
import xDec028862422 from 'assets/mapStyles/fillPatterns/x-Dec.02-1886.24-2.svg';
import vDec208911955 from 'assets/mapStyles/fillPatterns/v-Dec.20-1891.19-5.svg';
import vDec208911954 from 'assets/mapStyles/fillPatterns/v-Dec.20-1891.19-4.svg';
import vDec208911953 from 'assets/mapStyles/fillPatterns/v-Dec.20-1891.19-3.svg';
import vDec208911952 from 'assets/mapStyles/fillPatterns/v-Dec.20-1891.19-2.svg';
import vDec198862622 from 'assets/mapStyles/fillPatterns/v-Dec.19-1886.26-2.svg';
import vDec078861155 from 'assets/mapStyles/fillPatterns/v-Dec.07-1886.11-5.svg';
import vDec078861151 from 'assets/mapStyles/fillPatterns/v-Dec.07-1886.11-1.svg';
import vDec058862811 from 'assets/mapStyles/fillPatterns/v-Dec.05-1886.28-1.svg';
import pxDec128912522 from 'assets/mapStyles/fillPatterns/px-Dec.12-1891.25-2.svg';
import miscgDec048861733 from 'assets/mapStyles/fillPatterns/misc-g-Dec.04-1886.17-3.svg';
import miscgDec048861711 from 'assets/mapStyles/fillPatterns/misc-g-Dec.04-1886.17-1.svg';
import miscdardarDec128912577 from 'assets/mapStyles/fillPatterns/misc-dar-dar-Dec.12-1891.25-7.svg';
import hDec238813077 from 'assets/mapStyles/fillPatterns/h-Dec.23-1881.30-7.svg';
import hDec238813076 from 'assets/mapStyles/fillPatterns/h-Dec.23-1881.30-6.svg';
import hDec238813075 from 'assets/mapStyles/fillPatterns/h-Dec.23-1881.30-5.svg';
import hDec189002822 from 'assets/mapStyles/fillPatterns/h-Dec.18-1900.28-2.svg';
import hDec148821844 from 'assets/mapStyles/fillPatterns/h-Dec.14-1882.18-4.svg';
import hDec148821842 from 'assets/mapStyles/fillPatterns/h-Dec.14-1882.18-2.svg';
import hDec058862833 from 'assets/mapStyles/fillPatterns/h-Dec.05-1886.28-3.svg';
import hDec058862832 from 'assets/mapStyles/fillPatterns/h-Dec.05-1886.28-2.svg';
import hDec018832166 from 'assets/mapStyles/fillPatterns/h-Dec.01-1883.21-6.svg';
import hDec018832165 from 'assets/mapStyles/fillPatterns/h-Dec.01-1883.21-5.svg';
import hDec018832163 from 'assets/mapStyles/fillPatterns/h-Dec.01-1883.21-3.svg';
import hDec018832162 from 'assets/mapStyles/fillPatterns/h-Dec.01-1883.21-2.svg';
import gDec238813088 from 'assets/mapStyles/fillPatterns/g-Dec.23-1881.30-8.svg';
import gDec078861144 from 'assets/mapStyles/fillPatterns/g-Dec.07-1886.11-4.svg';
import dvDec208911966 from 'assets/mapStyles/fillPatterns/dv-Dec.20-1891.19-6.svg';
import darDec128912533 from 'assets/mapStyles/fillPatterns/dar-Dec.12-1891.25-3.svg';
import arDec228811311 from 'assets/mapStyles/fillPatterns/ar-Dec.22-1881.13-1.svg';
import arDec211881144 from 'assets/mapStyles/fillPatterns/ar-Dec.21-1881.14-4.svg';
import arDec211881142 from 'assets/mapStyles/fillPatterns/ar-Dec.21-1881.14-2.svg';
import arDec168833144 from 'assets/mapStyles/fillPatterns/ar-Dec.16-1883.31-4.svg';
import arDec128912566 from 'assets/mapStyles/fillPatterns/ar-Dec.12-1891.25-6.svg';
import arDec068800766 from 'assets/mapStyles/fillPatterns/ar-Dec.06-1880.07-6.svg';
import arDec038811221 from 'assets/mapStyles/fillPatterns/ar-Dec.03-1881.12-1.svg';
import alDec158861822 from 'assets/mapStyles/fillPatterns/al-Dec.15-1886.18-2.svg';
import alDec088872244 from 'assets/mapStyles/fillPatterns/al-Dec.08-1887.22-4.svg';
import alDec088872233 from 'assets/mapStyles/fillPatterns/al-Dec.08-1887.22-3.svg';
import alDec088872222 from 'assets/mapStyles/fillPatterns/al-Dec.08-1887.22-2.svg';
import alDec088872211 from 'assets/mapStyles/fillPatterns/al-Dec.08-1887.22-1.svg';
import alDec028862488 from 'assets/mapStyles/fillPatterns/al-Dec.02-1886.24-8.svg';
import alDec028862433 from 'assets/mapStyles/fillPatterns/al-Dec.02-1886.24-3.svg';

export const PATTERNS = [
  { id: 'x-Dec.23-1881.30-1', name: 'Cross Pattern 1', image: xDec231881301 },
  { id: 'x-Dec.21-1881.14-1', name: 'Cross Pattern 2', image: xDec211881141 },
  { id: 'x-Dec.06-1880.07-7', name: 'Cross Pattern 3', image: xDec068800777 },
  { id: 'x-Dec.03-1881.12-3', name: 'Cross Pattern 4', image: xDec038811123 },
  { id: 'x-Dec.03-1881.12-2', name: 'Cross Pattern 5', image: xDec038811122 },
  { id: 'x-Dec.02-1886.24-5', name: 'Cross Pattern 6', image: xDec028862452 },
  { id: 'x-Dec.02-1886.24-2', name: 'Cross Pattern 7', image: xDec028862422 },
  { id: 'v-Dec.20-1891.19-5', name: 'Vertical Pattern 1', image: vDec208911955 },
  { id: 'v-Dec.20-1891.19-4', name: 'Vertical Pattern 2', image: vDec208911954 },
  { id: 'v-Dec.20-1891.19-3', name: 'Vertical Pattern 3', image: vDec208911953 },
  { id: 'v-Dec.20-1891.19-2', name: 'Vertical Pattern 4', image: vDec208911952 },
  { id: 'v-Dec.19-1886.26-2', name: 'Vertical Pattern 5', image: vDec198862622 },
  { id: 'v-Dec.07-1886.11-5', name: 'Vertical Pattern 6', image: vDec078861155 },
  { id: 'v-Dec.07-1886.11-1', name: 'Vertical Pattern 7', image: vDec078861151 },
  { id: 'v-Dec.05-1886.28-1', name: 'Vertical Pattern 8', image: vDec058862811 },
  { id: 'px-Dec.12-1891.25-2', name: 'Point-Diamond Pattern', image: pxDec128912522 },
  { id: 'misc-g-Dec.04-1886.17-3', name: 'Misc Grid Pattern 1', image: miscgDec048861733 },
  { id: 'misc-g-Dec.04-1886.17-1', name: 'Misc Grid Pattern 2', image: miscgDec048861711 },
  { id: 'misc-dar-dar-Dec.12-1891.25-7', name: 'Misc Dash Angle Right Pattern', image: miscdardarDec128912577 },
  { id: 'h-Dec.23-1881.30-7', name: 'Horizontal Pattern 1', image: hDec238813077 },
  { id: 'h-Dec.23-1881.30-6', name: 'Horizontal Pattern 2', image: hDec238813076 },
  { id: 'h-Dec.23-1881.30-5', name: 'Horizontal Pattern 3', image: hDec238813075 },
  { id: 'h-Dec.18-1900.28-2', name: 'Horizontal Pattern 4', image: hDec189002822 },
  { id: 'h-Dec.14-1882.18-4', name: 'Horizontal Pattern 5', image: hDec148821844 },
  { id: 'h-Dec.14-1882.18-2', name: 'Horizontal Pattern 6', image: hDec148821842 },
  { id: 'h-Dec.05-1886.28-3', name: 'Horizontal Pattern 7', image: hDec058862833 },
  { id: 'h-Dec.05-1886.28-2', name: 'Horizontal Pattern 8', image: hDec058862832 },
  { id: 'h-Dec.01-1883.21-6', name: 'Horizontal Pattern 9', image: hDec018832166 },
  { id: 'h-Dec.01-1883.21-5', name: 'Horizontal Pattern 10', image: hDec018832165 },
  { id: 'h-Dec.01-1883.21-3', name: 'Horizontal Pattern 11', image: hDec018832163 },
  { id: 'h-Dec.01-1883.21-2', name: 'Horizontal Pattern 12', image: hDec018832162 },
  { id: 'g-Dec.23-1881.30-8', name: 'Grid Pattern 1', image: gDec238813088 },
  { id: 'g-Dec.07-1886.11-4', name: 'Grid Pattern 2', image: gDec078861144 },
  { id: 'dv-Dec.20-1891.19-6', name: 'Dash Vertical Pattern', image: dvDec208911966 },
  { id: 'dar-Dec.12-1891.25-3', name: 'Dash Pattern', image: darDec128912533 },
  { id: 'ar-Dec.22-1881.13-1', name: 'Angle Right Pattern 1', image: arDec228811311 },
  { id: 'ar-Dec.21-1881.14-4', name: 'Angle Right Pattern 2', image: arDec211881144 },
  { id: 'ar-Dec.21-1881.14-2', name: 'Angle Right Pattern 3', image: arDec211881142 },
  { id: 'ar-Dec.16-1883.31-4', name: 'Angle Right Pattern 4', image: arDec168833144 },
  { id: 'ar-Dec.12-1891.25-6', name: 'Angle Right Pattern 5', image: arDec128912566 },
  { id: 'ar-Dec.06-1880.07-6', name: 'Angle Right Pattern 6', image: arDec068800766 },
  { id: 'ar-Dec.03-1881.12-1', name: 'Angle Right Pattern 7', image: arDec038811221 },
  { id: 'al-Dec.15-1886.18-2', name: 'Angle Left Pattern 1', image: alDec158861822 },
  { id: 'al-Dec.08-1887.22-4', name: 'Angle Left Pattern 2', image: alDec088872244 },
  { id: 'al-Dec.08-1887.22-3', name: 'Angle Left Pattern 3', image: alDec088872233 },
  { id: 'al-Dec.08-1887.22-2', name: 'Angle Left Pattern 4', image: alDec088872222 },
  { id: 'al-Dec.08-1887.22-1', name: 'Angle Left Pattern 5', image: alDec088872211 },
  { id: 'al-Dec.02-1886.24-8', name: 'Angle Left Pattern 6', image: alDec028862488 },
  { id: 'al-Dec.02-1886.24-3', name: 'Angle Left Pattern 7', image: alDec028862433 }
]; 