import React from "react";
import { LoginForm } from "../../components/loginForm/loginForm";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "app/routing/routing";
import styles from "./login.module.scss";
import Layout from "components/layout/Layout";
import { Button, ButtonStyle } from "components/ui/button/button";
import SubHeading from "components/ui/typograhpy/subheading/subheading";
import Title from "components/ui/typograhpy/title/title";

export const Login = () => {
  const navigate = useNavigate();

  const onSignUpButtonClick = () => {
    navigate(RoutePaths.SignUp);
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.left}></div>
      <div className={styles.right}>
        <Title>District Maps</Title>
        <SubHeading>Please Log in</SubHeading>
        <LoginForm />
        <div>
          <Button
            type="button"
            onClick={onSignUpButtonClick}
            style={ButtonStyle.Secondary}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
};
