import { FC, ReactNode } from "react";
import styles from "./contentWrapper.module.scss";

interface ContentWrapperProps {
  children: ReactNode;
}

const ContentWrapper: FC<ContentWrapperProps> = ({ children }) => {
  return <div className={styles.contentWrapper}>{children}</div>;
};

export default ContentWrapper;
