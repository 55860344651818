import React, { FC, useState, useRef, useEffect } from 'react';
import { Field } from "components/ui/field/field";
import { DropdownOption } from "components/ui/optionDropdown/optionDropdown";
import { UseFormSetValue } from 'react-hook-form';
import { ReferenceDocumentFormFields } from '../referenceDocumentForm/referenceDocumentForm';
import styles from "./studyAreaBoundary.module.scss";
import IconCheckGreen from 'assets/images/icons/icon-check-green.svg';
import IconTrash from 'assets/images/icons/icon-trash.svg';
import { fetchStudyAreaBoundaries, createStudyAreaBoundary, deleteStudyAreaBoundary, updateStudyAreaBoundary } from 'actions/adminActions';
import { useAdminUpdate, useAdminState } from 'context/admin';
import { ConfirmationDialog } from 'components/shared/confirmationDialog/confirmationDialog';
import { Dialog } from 'components/shared/dialog/dialog';
import maplibregl from 'maplibre-gl';
import { GeoJSON, StudyAreaBoundary } from "actions/lib/types";
import Select from 'react-select';
import { Map } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  MaplibreExportControl,
  Size,
  PageOrientation,
  Format,
  DPI
} from '@watergis/maplibre-gl-export';
import '@watergis/maplibre-gl-export/dist/maplibre-gl-export.css';

interface BoundaryOption {
  value: number;
  label: string;
  geom?: string;
  extent?: number[];
  thumbnail?: string | null;
}

interface StudyAreaBoundaryProps {
  error: string | null;
  setError: (error: string | null) => void;
  boundaries: Array<{
    gid: number;
    name: string;
    thumbnail?: string | null;
    extent?: number[];
    geom?: string;
  }>;
  selectedBoundary: DropdownOption | undefined;
  setSelectedBoundary: (option: DropdownOption | undefined) => void;
  setValue: UseFormSetValue<ReferenceDocumentFormFields>;
}

interface Feature {
  value: number;
  label: string;
  geometry: any;
  properties: any;
}

interface PropertyOption {
  value: string;
  label: string;
}

interface PropertyValueOption {
  value: string;
  label: string;
}

const FEATURE_COLOR = '#088';
const FEATURE_OPACITY = 0.5;
const FEATURE_OUTLINE_COLOR = '#088';
const FEATURE_OUTLINE_WIDTH = 3;
const CANADA_CENTRE_COORDINATES = [-106.3468, 56.1304];

export const StudyAreaBoundaryComponent: FC<StudyAreaBoundaryProps> = ({
  error,
  setError,
  boundaries,
  selectedBoundary,
  setSelectedBoundary,
  setValue
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const map = useRef<maplibregl.Map | null>(null);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [newBoundaries, setNewBoundaries] = useState<BoundaryOption[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [nameEdited, setNameEdited] = useState(false);
  const [showFeatureDialog, setShowFeatureDialog] = useState(false);
  const [featureCollection, setFeatureCollection] = useState<Feature[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
  const featureDialogMapRef = useRef<maplibregl.Map | null>(null);
  const [propertyOptions, setPropertyOptions] = useState<PropertyOption[]>([]);
  const [selectedProperty, setSelectedProperty] = useState<PropertyOption | null>(null);
  const [propertyValueOptions, setPropertyValueOptions] = useState<PropertyValueOption[]>([]);
  const [selectedPropertyValue, setSelectedPropertyValue] = useState<PropertyValueOption | null>(null);

  const [boundaryOptions, setBoundaryOptions] = useState<DropdownOption[]>(
    boundaries.map(boundary => ({
      label: boundary.name,
      value: boundary.gid,
      thumbnail: boundary.thumbnail
    }))
  );

  const adminUpdate = useAdminUpdate();
  const adminState = useAdminState();

  const extractPropertyOptions = (features: Feature[]) => {
    const properties = new Set<string>();
    features.forEach(feature => {
      Object.keys(feature.properties).forEach(prop => properties.add(prop));
    });
    return Array.from(properties).map(prop => ({ value: prop, label: prop }));
  };

  const getPropertyValues = (property: string) => {
    const values = new Set<string>();
    featureCollection.forEach(feature => {
      const value = feature.properties[property];
      if (value !== undefined && value !== null) {
        values.add(value.toString());
      }
    });
    return Array.from(values).map(value => ({ value, label: value }));
  };

  const handleFeatureSelect = (feature: Feature | null) => {
    setSelectedFeature(feature);

    if (feature && featureDialogMapRef.current) {
      const source = featureDialogMapRef.current.getSource('preview-boundary') as maplibregl.GeoJSONSource;
      if (source) {
        source.setData({
          type: "Feature",
          properties: {},
          geometry: feature.geometry
        });
      }

      const extent = calculateExtent(feature.geometry);
      featureDialogMapRef.current.fitBounds(extent as [number, number, number, number], {
        padding: { top: 20, bottom: 20, left: 25, right: 25 },
        maxZoom: 14,
      });
    }
  };

  const handlePropertySelect = (option: PropertyOption | null) => {
    setSelectedProperty(option);
    setSelectedPropertyValue(null);
    if (option) {
      setPropertyValueOptions(getPropertyValues(option.value));
    } else {
      setPropertyValueOptions([]);
    }
  };

  const handlePropertyValueSelect = (option: PropertyValueOption | null) => {
    setSelectedPropertyValue(option);
    if (option && selectedProperty && featureDialogMapRef.current) {
      const matchingFeature = featureCollection.find(
        feature => feature.properties[selectedProperty.value]?.toString() === option.value
      );

      if (matchingFeature) {
        setSelectedFeature(matchingFeature);
        const source = featureDialogMapRef.current.getSource('preview-boundary') as maplibregl.GeoJSONSource;
        if (source) {
          source.setData({
            type: "Feature",
            properties: {},
            geometry: matchingFeature.geometry
          });
        }

        const extent = calculateExtent(matchingFeature.geometry);
        featureDialogMapRef.current.fitBounds(extent as [number, number, number, number], {
          padding: { top: 20, bottom: 20, left: 25, right: 25 },
          maxZoom: 14,
        });
      }
    }
  };

  const handleFeatureConfirm = () => {
    if (selectedFeature && map.current) {
      const customId = -(Date.now());
      const newBoundaryOption: BoundaryOption = {
        value: customId,
        label: selectedFeature.label,
        extent: calculateExtent(selectedFeature.geometry),
        geom: JSON.stringify(selectedFeature.geometry),
        thumbnail: null
      };

      setSelectedBoundary({
        value: customId,
        label: newBoundaryOption.label
      });
      setValue('boundary_id', customId);
      setNewBoundaries([newBoundaryOption]);

      const boundary_source = map.current.getSource('boundary') as maplibregl.GeoJSONSource;
      if (boundary_source) {
        boundary_source.setData({
          type: "Feature",
          properties: {},
          geometry: selectedFeature.geometry
        });
      }

      if (newBoundaryOption.extent) {
        map.current.fitBounds(newBoundaryOption.extent as [number, number, number, number], {
          padding: { top: 20, bottom: 20, left: 25, right: 25 },
          maxZoom: 14,
        });
      }
    }
    setShowFeatureDialog(false);
    setSelectedFeature(null);
  };

  // File validation
  const validateGeoJSONFile = (file: File): boolean => {
    if (file.type !== "application/json") {
      setError("Please drop a valid GeoJSON file.");
      return false;
    }
    return true;
  };

  // Parse GeoJSON and validate structure
  const parseGeoJSON = (jsonString: string): any => {
    try {
      const parsedJSON = JSON.parse(jsonString);
      if (parsedJSON.type !== 'FeatureCollection' && parsedJSON.type !== 'Feature') {
        setError("Invalid GeoJSON format. Must be a Feature or FeatureCollection.");
        return null;
      }
      return parsedJSON;
    } catch (err) {
      setError("Invalid GeoJSON file.");
      return null;
    }
  };

  // Handle FeatureCollection
  const handleFeatureCollection = (features: any[]): void => {
    const mappedFeatures: Feature[] = features.map((feature: any, index: number) => ({
      value: index,
      label: feature.properties?.name || `Feature ${index + 1}`,
      geometry: feature.geometry,
      properties: feature.properties || {}
    }));

    setFeatureCollection(mappedFeatures);
    setShowFeatureDialog(true);
    initializeFeatureDialogMap();
  };

  // Handle single Feature
  const handleSingleFeature = (feature: any): void => {
    const customId = -(Date.now());
    const newBoundaryOption: BoundaryOption = {
      value: customId,
      label: feature.properties?.name || 'New Boundary',
      extent: calculateExtent(feature.geometry),
      geom: JSON.stringify(feature.geometry),
      thumbnail: null
    };

    updateBoundaryStates(newBoundaryOption);
    updateMapWithFeature(feature.geometry, newBoundaryOption.extent);
  };

  // Initialize Feature Dialog Map
  const initializeFeatureDialogMap = (): void => {
    requestAnimationFrame(() => {
      if (!featureDialogMapRef.current) {
        featureDialogMapRef.current = new maplibregl.Map({
          container: 'feature-preview-map',
          style: 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
          zoom: 10,
          center: CANADA_CENTRE_COORDINATES as [number, number],
          attributionControl: false
        });

        featureDialogMapRef.current.on('load', () => {
          initializeMapLayers();
        });
      }
    });
  };

  // Initialize Map Layers
  const initializeMapLayers = (): void => {
    if (!featureDialogMapRef.current) return;

    featureDialogMapRef.current.addSource('preview-boundary', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: []
        }
      }
    });

    featureDialogMapRef.current.addLayer({
      id: 'preview-boundary',
      type: 'fill',
      source: 'preview-boundary',
      paint: {
        'fill-color': FEATURE_COLOR,
        'fill-opacity': FEATURE_OPACITY
      }
    });

    featureDialogMapRef.current.addLayer({
      id: 'preview-outline',
      type: 'line',
      source: 'preview-boundary',
      paint: {
        'line-color': FEATURE_OUTLINE_COLOR,
        'line-width': FEATURE_OUTLINE_WIDTH
      }
    });
  };

  // Update boundary states
  const updateBoundaryStates = (boundaryOption: BoundaryOption): void => {
    setSelectedBoundary({
      value: boundaryOption.value,
      label: boundaryOption.label
    });
    setValue('boundary_id', boundaryOption.value);
    setNewBoundaries([boundaryOption]);
  };

  // Update map with feature
  const updateMapWithFeature = (geometry: any, extent: number[] | undefined): void => {
    if (!map.current) return;

    const boundary_source = map.current.getSource('boundary') as maplibregl.GeoJSONSource;
    if (boundary_source) {
      boundary_source.setData({
        type: "Feature",
        properties: {},
        geometry: geometry
      });
    }

    if (extent) {
      map.current.fitBounds(extent as [number, number, number, number], {
        padding: { top: 20, bottom: 20, left: 25, right: 25 },
        maxZoom: 14,
      });
    }
  };

  // Main handler function
  const handleMapDrop = (e: DragEvent): void => {
    e.preventDefault();
    setError(null);

    const file = e.dataTransfer?.files[0];
    if (!file) return;

    if (!validateGeoJSONFile(file)) return;

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && typeof e.target.result === 'string') {
        const parsedJSON = parseGeoJSON(e.target.result);
        if (!parsedJSON) return;

        if (parsedJSON.type === 'FeatureCollection' && parsedJSON.features?.length > 0) {
          handleFeatureCollection(parsedJSON.features);
        } else if (parsedJSON.type === 'Feature') {
          handleSingleFeature(parsedJSON);
        }
      }
    };
    reader.readAsText(file);
  };

  const handleMapDragOver = (e: DragEvent) => {
    e.preventDefault();
  };

  // Initialize map
  useEffect(() => {
    map.current = new maplibregl.Map({
      container: 'map',
      style: 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
      zoom: 10,
      center: CANADA_CENTRE_COORDINATES as [number, number],
      attributionControl: false
    });

    const mapContainer = document.getElementById('map');
    if (mapContainer) {
      const dropHandler = (e: Event) => {
        if (e instanceof DragEvent) {
          handleMapDrop(e);
        }
      };

      const dragOverHandler = (e: Event) => {
        if (e instanceof DragEvent) {
          handleMapDragOver(e);
        }
      };

      mapContainer.addEventListener('drop', dropHandler);
      mapContainer.addEventListener('dragover', dragOverHandler);

      map.current.on('load', () => {
        if (!map.current) return;
        map.current.addSource('boundary', {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
              'type': 'Polygon',
              'coordinates': []
            }
          }
        });
        map.current.addLayer({
          'id': 'boundary',
          'type': 'fill',
          'source': 'boundary',
          'layout': {},
          'paint': {
            'fill-color': FEATURE_COLOR, 
            'fill-opacity': FEATURE_OPACITY
          }
        });
        map.current.addLayer({
          'id': 'outline',
          'type': 'line',
          'source': 'boundary',
          'layout': {},
          'paint': {
            'line-color': FEATURE_OUTLINE_COLOR,
            'line-width': FEATURE_OUTLINE_WIDTH
          }
        });

        setMapLoaded(true);
      });

      const exportControl = new MaplibreExportControl({
        PageSize: Size.A3,
        PageOrientation: PageOrientation.Portrait,
        Format: Format.PNG,
        DPI: DPI[96],
        Crosshair: true,
        PrintableArea: true,
        Local: 'en',
        
      });
      map.current.addControl(exportControl, 'top-right');

      return () => {
        if (map.current) {
          map.current.remove();
        }
        if (mapContainer) {
          mapContainer.removeEventListener('drop', dropHandler);
          mapContainer.removeEventListener('dragover', dragOverHandler);
        }
      };
    }
  }, []);

  // Handle boundary updates
  useEffect(() => {
    if (mapLoaded && selectedBoundary && map.current) {
      const boundaryOption = [...boundaries, ...newBoundaries].find(
        b => ('gid' in b ? b.gid === selectedBoundary.value : b.value === selectedBoundary.value)
      );

      if (boundaryOption) {
        const boundaryDetails = boundaryOption as BoundaryOption;
        const extent = boundaryDetails.extent;
        const geom = boundaryDetails.geom;

        if (extent && geom) {
          const bounds: [number, number, number, number] = [
            extent[0],
            extent[1],
            extent[2],
            extent[3]
          ];

          map.current.fitBounds(bounds, {
            padding: { top: 20, bottom: 20, left: 25, right: 25 },
            maxZoom: 14,
          });

          const boundary_source = map.current.getSource('boundary') as maplibregl.GeoJSONSource;
          if (boundary_source) {
            boundary_source.setData({
              "type": "Feature",
              "properties": {},
              "geometry": JSON.parse(geom || '{}')
            });
          }
        }
      }
    }
  }, [mapLoaded, selectedBoundary, boundaries, newBoundaries]);

  const calculateExtent = (geometry: any): [number, number, number, number] => {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    if (geometry.type === 'Polygon') {
      geometry.coordinates[0].forEach((coord: [number, number]) => {
        minX = Math.min(minX, coord[0]);
        minY = Math.min(minY, coord[1]);
        maxX = Math.max(maxX, coord[0]);
        maxY = Math.max(maxY, coord[1]);
      });
    }

    return [minX, minY, maxX, maxY];
  };

  const handleBoundaryChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (option) {
      setValue('boundary_id', parseInt(option.value.toString()));
      setSelectedBoundary(option);

      const selectedBoundaryDetails = [...boundaries, ...newBoundaries].find(b =>
        'gid' in b ? b.gid === option.value : b.value === option.value
      );

      if (selectedBoundaryDetails) {
        const boundaryDetails = selectedBoundaryDetails as BoundaryOption;
        if (map.current) {
          const boundary_source = map.current.getSource('boundary') as maplibregl.GeoJSONSource;
          if (boundary_source) {
            boundary_source.setData({
              "type": "Feature",
              "properties": {},
              "geometry": JSON.parse(boundaryDetails.geom || '{}')
            });
          }
        }
      }
    } else {
      setValue('boundary_id', undefined);
      setSelectedBoundary(undefined);

      if (map.current) {
        const boundary_source = map.current.getSource('boundary') as maplibregl.GeoJSONSource;
        if (boundary_source) {
          boundary_source.setData({
            "type": "Feature",
            "properties": {},
            "geometry": {
              "type": "Polygon",
              "coordinates": []
            }
          });
        }
      }
    }
  };

  const handleSaveConfirm = async () => {
    try {
      if (!selectedBoundary) return;

      const boundaryValue = Number(selectedBoundary.value);

      if (boundaryValue < 0) {
        // Handle new boundary creation
        const boundaryData = newBoundaries.find(b => b.value === selectedBoundary.value);
        if (boundaryData) {
          const savedBoundary = await createStudyAreaBoundary({
            name: boundaryData.label,
            geom: boundaryData.geom,
            extent: boundaryData.extent
          });

          // Update states with the new boundary
          const newSelectedOption = {
            value: savedBoundary?.data?.gid,
            label: savedBoundary?.data?.name,
            thumbnail: savedBoundary?.data?.thumbnail
          };
          setSelectedBoundary(newSelectedOption);
          setValue('boundary_id', savedBoundary?.data?.gid);
          setNewBoundaries([]);
        }
      } else {
        await updateStudyAreaBoundary({ name: selectedBoundary.label }, parseInt(selectedBoundary.value.toString()));
      }

      // Fetch and update the boundaries list
      const updatedBoundaries = await fetchStudyAreaBoundaries();

      // Update the dropdown options
      const newBoundaryOptions = updatedBoundaries.map((boundary: StudyAreaBoundary) => ({
        label: boundary.name,
        value: boundary.gid,
        thumbnail: boundary.thumbnail
      }));
      setBoundaryOptions(newBoundaryOptions);

      // Update the admin state with new boundaries
      if (adminUpdate && adminState) {
        adminUpdate({
          ...adminState,
          studyAreaBoundaryList: updatedBoundaries
        });
      }
    } catch (error) {
      console.error('Error saving boundary:', error);
    }

    setNameEdited(false);
    setShowSaveDialog(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedBoundary) {
        const boundaryValue = Number(selectedBoundary.value);

        setSelectedBoundary(undefined);
        setValue('boundary_id', undefined);

        // Clear the boundary from the map
        if (map.current) {
          const boundary_source = map.current.getSource('boundary') as maplibregl.GeoJSONSource;
          if (boundary_source) {
            boundary_source.setData({
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates: []
              }
            });
          }
        }

        if (boundaryValue > 0) {
          await deleteStudyAreaBoundary(boundaryValue);

          // Fetch and update the boundaries list
          const updatedBoundaries = await fetchStudyAreaBoundaries();
          const boundaryOptions = updatedBoundaries.map((boundary: StudyAreaBoundary) => ({
            label: boundary.name,
            value: boundary.gid,
            thumbnail: boundary.thumbnail
          }));
          setBoundaryOptions(boundaryOptions);

          // Update the admin state with new boundaries
          if (adminUpdate && adminState) {
            adminUpdate({
              ...adminState,
              studyAreaBoundaryList: updatedBoundaries
            });
          }
        }
      }
    } catch (error) {
      console.error('Error deleting boundary:', error);
    }
    setShowDeleteDialog(false);
  };

  const handleDoubleClick = () => {
    if (selectedBoundary) {
      setEditedName(selectedBoundary.label);
      setIsEditing(true);
      // Focus the input field after it renders
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      updateBoundaryName();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const updateBoundaryName = () => {
    if (selectedBoundary && editedName.trim()) {
      // Update the selected boundary
      setSelectedBoundary({
        ...selectedBoundary,
        label: editedName
      });

      // Update in newBoundaries if it's a new boundary
      if (Number(selectedBoundary.value) < 0) {
        setNewBoundaries(prev =>
          prev.map(b =>
            b.value === selectedBoundary.value
              ? { ...b, label: editedName }
              : b
          )
        );
      }
      setNameEdited(true);
    }
    setIsEditing(false);
  };

  // Add cleanup function for the feature dialog map
  useEffect(() => {
    if (!showFeatureDialog && featureDialogMapRef.current) {
      featureDialogMapRef.current.remove();
      featureDialogMapRef.current = null;
    }
  }, [showFeatureDialog]);

  // Update the Dialog content JSX
  <Dialog
    isOpen={showFeatureDialog}
    onClose={() => setShowFeatureDialog(false)}
    onConfirm={handleFeatureConfirm}
    title="Select Boundary Feature"
    confirmDisabled={!selectedFeature}
  >
    <div className={styles.featureDialogContent}>
      <div id="feature-preview-map" className={styles.featurePreviewMap}></div>
      <div className={styles.featureSelectors}>
        <div className={styles.selectContainer}>
          <label>Properties</label>
          <Select
            options={propertyOptions}
            value={selectedProperty}
            onChange={handlePropertySelect}
            placeholder="Select a property..."
            isClearable
          />
        </div>
        <div className={styles.selectContainer}>
          <label>Property Values</label>
          <Select
            options={propertyValueOptions}
            value={selectedPropertyValue}
            onChange={handlePropertyValueSelect}
            placeholder="Select a value..."
            isDisabled={!selectedProperty}
            isClearable
          />
        </div>
        {selectedFeature && (
          <div className={styles.propertyList}>
            <label>Feature Properties:</label>
            <div className={styles.propertyTable}>
              {Object.entries(selectedFeature.properties as Record<string, any>).map(([key, value]) => (
                <div key={key} className={styles.propertyRow}>
                  <span className={styles.propertyKey}>{key}:</span>
                  <span className={styles.propertyValue}>{String(value)}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  </Dialog>

  // Update the useEffect that handles feature collection setting
  useEffect(() => {
    if (featureCollection.length > 0) {
      const properties = extractPropertyOptions(featureCollection);
      setPropertyOptions(properties);
    }
  }, [featureCollection]);

  return (
    <div className={styles.section}>
      <label>STUDY AREA BOUNDARY</label>
      <hr className={styles.solid}></hr>
      <div className={styles.mapContainer}>
        <div id="map" className={styles.map}></div>
      </div>
      {error && (
        <div className={styles.mapError}>
          {error}
        </div>
      )}

      {selectedBoundary && (
        <div className={styles.selectedBoundaryInfo}>
          <div className={styles.boundaryDetails}>
            {isEditing ? (
              <input
                ref={inputRef}
                type="text"
                value={editedName}
                onChange={handleNameChange}
                onBlur={updateBoundaryName}
                onKeyDown={handleKeyPress}
                className={styles.boundaryNameInput}
              />
            ) : (
              <span
                className={styles.boundaryName}
                onDoubleClick={handleDoubleClick}
              >
                {selectedBoundary.label}
              </span>
            )}
          </div>
          <div className={styles.boundaryActions}>
            {(Number(selectedBoundary.value) < 0 || nameEdited) && (
              <button
                type="button"
                className={styles.saveButton}
                onClick={() => setShowSaveDialog(true)}
                title="Save Boundary"
              >
                <img src={IconCheckGreen} alt="Save" />
              </button>
            )}
            <button
              type="button"
              className={styles.deleteButton}
              onClick={() => setShowDeleteDialog(true)}
              title="Remove Boundary"
            >
              <img src={IconTrash} alt="Delete" />
            </button>
          </div>
        </div>
      )}

      <div className={styles.field}>
        <Field
          labelName={'Select Doc Boundary from District Database'}
          fieldName={'boundary_id'}
          fieldType={'selectDropDown'}
          options={boundaryOptions}
          handleOptionChange={handleBoundaryChange}
          isSearchable={true}
          selectedOption={selectedBoundary}
        />
      </div>

      <Dialog
        isOpen={showFeatureDialog}
        onClose={() => setShowFeatureDialog(false)}
        onConfirm={handleFeatureConfirm}
        title="Select Boundary Feature"
        confirmDisabled={!selectedFeature}
      >
        <div className={styles.featureDialogContent}>
          <div id="feature-preview-map" className={styles.featurePreviewMap}></div>
          <div className={styles.featureSelectors}>
            <div className={styles.selectContainer}>
              <label>Properties</label>
              <Select
                options={propertyOptions}
                value={selectedProperty}
                onChange={handlePropertySelect}
                placeholder="Select a property..."
                isClearable
              />
            </div>
            <div className={styles.selectContainer}>
              <label>Property Values</label>
              <Select
                options={propertyValueOptions}
                value={selectedPropertyValue}
                onChange={handlePropertyValueSelect}
                placeholder="Select a value..."
                isDisabled={!selectedProperty}
                isClearable
              />
            </div>
            {selectedFeature && (
              <div className={styles.propertyList}>
                <label>Feature Properties:</label>
                <div className={styles.propertyTable}>
                  {Object.entries(selectedFeature.properties as Record<string, any>).map(([key, value]) => (
                    <div key={key} className={styles.propertyRow}>
                      <span className={styles.propertyKey}>{key}:</span>
                      <span className={styles.propertyValue}>{String(value)}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <ConfirmationDialog
        isOpen={showSaveDialog}
        title="Save Boundary"
        message={`Are you sure you want to save the boundary "${selectedBoundary?.label}"?`}
        onConfirm={handleSaveConfirm}
        onCancel={() => setShowSaveDialog(false)}
        confirmText="Save"
        cancelText="Cancel"
      />

      <ConfirmationDialog
        isOpen={showDeleteDialog}
        title="Delete Boundary"
        message={`Are you sure you want to delete the boundary "${selectedBoundary?.label}"?`}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setShowDeleteDialog(false)}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </div>
  );
};

export default StudyAreaBoundaryComponent; 