import React, { useEffect, useRef, useState } from 'react';
import maplibregl, { Map as MaplibreMap } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  MaplibreExportControl,
  Size,
  PageOrientation,
  Format,
  DPI
} from '@watergis/maplibre-gl-export';
import '@watergis/maplibre-gl-export/dist/maplibre-gl-export.css';
import styles from './map.module.scss';

export interface MapProps {
  /** Container className for custom styling */
  className?: string;
  /** Initial center coordinates [longitude, latitude] */
  center?: [number, number];
  /** Initial zoom level */
  zoom?: number;
  /** Enable/disable map export control */
  showExportControl?: boolean;
  /** Enable/disable attribution control */
  showAttribution?: boolean;
  /** Callback when map is loaded */
  onMapLoaded?: (map: MaplibreMap) => void;
  /** Callback when map is initialized */
  onMapInitialized?: (map: MaplibreMap) => void;
  /** Custom map style URL */
  styleUrl?: string;
  /** Children components to render over the map */
  children?: React.ReactNode;
}

export const MapComponent: React.FC<MapProps> = ({
  className,
  center = [-106.3468, 56.1304], // Default to Canada center
  zoom = 10,
  showExportControl = false,
  onMapLoaded,
  onMapInitialized,
  styleUrl = 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
  children
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<MaplibreMap | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (!mapContainer.current) return;

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: styleUrl,
      center: center,
      zoom: zoom,
      attributionControl: false
    });

    if (onMapInitialized) {
      onMapInitialized(map.current);
    }

    map.current.on('load', () => {
      if (!map.current) return;

      if (showExportControl) {
        const exportControl = new MaplibreExportControl({
          PageSize: Size.A3,
          PageOrientation: PageOrientation.Portrait,
          Format: Format.PNG,
          DPI: DPI[96],
          Crosshair: true,
          PrintableArea: true,
          Local: 'en',
        });
        map.current.addControl(exportControl, 'top-right');
      }

      setMapLoaded(true);
      
      if (onMapLoaded) {
        onMapLoaded(map.current);
      }
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, []);

  return (
    <div 
      ref={mapContainer} 
      className={`${styles.mapContainer} ${className || ''}`}
    >
      {mapLoaded && children}
    </div>
  );
};

// Hook to access the map instance
export const useMap = () => {
  const map = useRef<MaplibreMap | null>(null);

  const setMap = (newMap: MaplibreMap) => {
    map.current = newMap;
  };

  return {
    map: map.current,
    setMap
  };
};

export default MapComponent; 