import { FC, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, ButtonStyle } from 'components/ui/button/button';
import { Field, DropdownOption } from "components/ui/field/field";
import { debounce } from 'lodash';
import { fetchGeocodingResults, fetchAddressDetails, updateOrgAuthor, createOrgAuthor } from 'actions/adminActions';
import styles from "./organizationForm.module.scss";
import { useAdminState } from 'context/admin';
import { OrgAuthorType } from 'actions/lib/types';

export interface OrganizationFormFields {
  organizationType: string;
  organizationName: string;
  formerName: string;
  phone: string;
  email: string;
  website: string;
  unitNumber: string;
  street: string;
  municipality: string;
  region: string;
  province: string;
  country: string;
  postalCode: string;
}

const CANADA_LOCATION_SUFFIX = 'Canada';
interface OrganizationFormProps {
  selectedOrg: any;
  isCreating?: boolean;
  onCancel?: () => void;
}

const OrganizationForm: FC<OrganizationFormProps> = ({ selectedOrg, isCreating, onCancel }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<OrganizationFormFields>();

  const adminState = useAdminState();

  const {
    orgAuthorTypeList,
  } = adminState;

  const organizationTypes = orgAuthorTypeList.map((orgAuthorType: OrgAuthorType) => ({
    value: orgAuthorType.id.toString(),
    label: orgAuthorType.name,
  }));

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOrgType, setSelectedOrgType] = useState<DropdownOption | undefined>();

  const debouncedSearch = useRef(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length >= 3) {
        const results = await fetchGeocodingResults(searchTerm, CANADA_LOCATION_SUFFIX);
        setSearchResults(results?.features || []);
        setShowAutocomplete(true);
      } else {
        setSearchResults([]);
        setShowAutocomplete(false);
      }
    }, 300)
  ).current;

  const handleOrganizationNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target instanceof HTMLInputElement) {
      const value = event.target.value;
      setValue('organizationName', value);
      debouncedSearch(value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && showAutocomplete) {
      event.preventDefault();
      setShowAutocomplete(false);
    } else if (event.key === 'Escape' && showAutocomplete) {
      event.preventDefault();
      setShowAutocomplete(false);
    }
  };

  const handleAutocompleteSelect = async (feature: any) => {
    const properties = feature.properties;
    
    // Construct OSM ID from osm_type and osm_id
    const osmIdWithType = `${properties.osm_type.charAt(0).toUpperCase()}${properties.osm_id}`;
    
    try {
      const addressDetails = await fetchAddressDetails(osmIdWithType);
      
      if (addressDetails && addressDetails[0]) {
        const address = addressDetails[0].address;
        
        // Populate form fields with detailed address
        setValue('organizationName', properties.name || '');
        setValue('unitNumber', address.house_number || '');
        setValue('street', address.road || properties.street || '');
        setValue('municipality', address.city || address.town || address.village || properties.city || '');
        setValue('region', address.state_district || address.county || '');
        setValue('province', address.state || properties.state || '');
        setValue('country', address.country || properties.country || '');
        setValue('postalCode', address.postcode || properties.postcode || '');
      } else {
        // Fallback to basic properties if detailed lookup fails
        setValue('organizationName', properties.name || '');
        setValue('street', properties.street || '');
        setValue('municipality', properties.city || '');
        setValue('province', properties.state || '');
        setValue('country', properties.country || '');
        setValue('postalCode', properties.postcode || '');
      }
    } catch (error) {
      console.error('Error fetching address details:', error);
      // Fallback to basic properties if detailed lookup fails
      setValue('organizationName', properties.name || '');
      setValue('street', properties.street || '');
      setValue('municipality', properties.city || '');
      setValue('province', properties.state || '');
      setValue('country', properties.country || '');
      setValue('postalCode', properties.postcode || '');
    }
    
    setShowAutocomplete(false);
  };

  useEffect(() => {
    if (selectedOrg) {
      // Find the matching organization type option
      const matchingOrgType = organizationTypes.find(
        (type) => type.value === selectedOrg.type_id?.toString()
      );

      // Basic information
      setValue('organizationName', selectedOrg.name);
      setValue('formerName', selectedOrg.former_name);
      setValue('organizationType', selectedOrg.type_id?.toString());
      
      // Set the selected option for the dropdown
      if (matchingOrgType) {
        setSelectedOrgType(matchingOrgType);
      }

      // Contact information
      setValue('phone', selectedOrg.phone);
      setValue('email', selectedOrg.email);
      setValue('website', selectedOrg.website);
      
      // Location information
      setValue('unitNumber', selectedOrg.unit_number);
      setValue('street', selectedOrg.street);
      setValue('municipality', selectedOrg.municipality);
      setValue('region', selectedOrg.region);
      setValue('province', selectedOrg.state);
      setValue('country', selectedOrg.country);
      setValue('postalCode', selectedOrg.postal_code);
    }
  }, [selectedOrg, setValue]);

  const handleOrganizationTypeChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (option) {
      setValue('organizationType', String(option.value));
      setSelectedOrgType(option);
    } else {
      setValue('organizationType', '');
      setSelectedOrgType(undefined);
    }
  };

  const onSubmit = async (values: OrganizationFormFields) => {
    try {
      const orgAuthorData = {
        name: values.organizationName,
        former_name: values.formerName || null,
        type_id: parseInt(values.organizationType) || null,
        phone: values.phone || null,
        email: values.email || null,
        website: values.website || null,
        unit_number: values.unitNumber || null,
        street: values.street || null,
        municipality: values.municipality || null,
        region: values.region || null,
        state: values.province || null,
        country: values.country || null,
        postal_code: values.postalCode || null
      };

      if (isCreating) {
        await createOrgAuthor(orgAuthorData);
      } else {
        await updateOrgAuthor(orgAuthorData, selectedOrg.id);
      }

      navigate('/organization');
      window.location.reload();
    } catch (error) {
      console.error('Error updating organization:', error);
    }
  };

  return (
    <form 
      onSubmit={(e) => {
        e.preventDefault();
        if (isSubmitting) {
          handleSubmit(onSubmit)(e);
        }
      }} 
      className={styles.form}
    >


      <Field
        labelName={'Organization Type'}
        fieldName={'organizationType'}
        fieldType={'selectDropDown'}
        options={organizationTypes}
        handleOptionChange={handleOrganizationTypeChange}
        selectedOption={selectedOrgType}
        isSearchable={true}
        placeholder="Select Organization Type"
      />

      <div className={styles.autocompleteContainer}>
        <Field
          labelName={'Organization Name'}
          fieldName={'organizationName'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.textField}
          onFieldChange={handleOrganizationNameChange}
          onKeyDown={handleKeyDown}
        />
        
        {showAutocomplete && searchResults.length > 0 && (
          <div className={styles.autocompleteDropdown}>
            {searchResults.map((result, index) => (
              <div
                key={index}
                className={styles.autocompleteItem}
                onClick={() => handleAutocompleteSelect(result)}
              >
                {result.properties.name || result.properties.street}
                {result.properties.city && `, ${result.properties.city}`}
                {result.properties.country && `, ${result.properties.country}`}
              </div>
            ))}
          </div>
        )}
      </div>

      <Field
        labelName={'Former Name'}
        fieldName={'formerName'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <label>CONTACT INFORMATION</label>
      <hr className={styles.solid}></hr>

      <Field
        labelName={'Phone'}
        fieldName={'phone'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.halfLength}
      />

      <Field
        labelName={'Email'}
        fieldName={'email'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Website'}
        fieldName={'website'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <label>LOCATION</label>
      <hr className={styles.solid}></hr>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Unit Number'}
          fieldName={'unitNumber'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Street'}
          fieldName={'street'}
          fieldType={'textBox'}
          register={register}
        />
      </div>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Municiplaity / County'}
          fieldName={'municipality'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Region / District'}
          fieldName={'region'}
          fieldType={'textBox'}
          register={register}
        />
      </div>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Province / State'}
          fieldName={'province'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Country'}
          fieldName={'country'}
          fieldType={'textBox'}
          register={register}
        />
      </div>

      <Field
        labelName={'Postal Code / ZIP'}
        fieldName={'postalCode'}
        fieldType={'textBox'}
        register={register}
        customClassName={styles.halfLength}
      />

      <div className={styles.buttonGroup}>
        <Button 
          className={styles.cancelButton} 
          type="button"
          style={ButtonStyle.Secondary}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button 
          className={styles.submitButton} 
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            setIsSubmitting(true);
            handleSubmit(onSubmit)(e);
          }}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default OrganizationForm;
