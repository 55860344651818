import { FC, ReactNode } from "react";

import styles from "./title.module.scss";

interface TitleProps {
  children: ReactNode;
}

const Title: FC<TitleProps> = ({ children }) => {
  return (
    <h1 className={styles.title}>{children}</h1>
  );
};

export default Title;
