import { FC, ReactNode } from 'react';
import { AppContextProvider } from './app';
import { UserContextProvider } from './user';

export { useUserState, useSetUserState, useMergeUserState } from './user';
export { useAppState, useSetAppState, useMergeAppState } from './app';

export interface AppContextInterface {
  token?: string;
  email?: string;
  userName?: string;
}

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: FC<AppProviderProps> = ({children}) => {
  return (
    <AppContextProvider>
      <UserContextProvider>
        {children}
      </UserContextProvider>
    </AppContextProvider>
  );
};
