import { FC } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import { Home } from "pages/home/home";
import { Login } from "pages/login/login";
import { SignUp } from "pages/signUp/signUp";
import { Upload } from "pages/upload/upload";
import { EncryptedContent } from "pages/encryptedContent/encryptedContent";
import { useAppState } from "context";
import { StyleGuide } from "pages/styleGuide/styleGuide";
import { UserPage } from "pages/userPage/userPage";
import { EditUser } from "pages/editUser/editUser";
import { SiteCheckup } from "pages/siteCheckup/siteCheckup";
import { Projects } from "pages/projects/projects";
import { Map } from "pages/map/map";
import { MapExp } from "pages/map/mapExp";
import { Contacts } from "pages/contacts/contacts";
import { Admin } from "pages/admin/admin";
import { Study } from "pages/admin/subpages/study/study";
import { Organization } from "pages/admin/subpages/organization/organization";
import { Users } from "pages/users/users";
import { Files } from "pages/files/files";

export enum RoutePaths {
  Home = "/",
  UserPage = "/user",
  EditUser = "/user-edit",
  Contacts = "/contacts",
  StyleGuide = "/style-guide",
  Login = "/login",
  SignUp = "/signUp",
  Upload = "/upload",
  EncryptedContent = "/encryptedContent",
  SiteCheckup = "/siteCheckup",
  Projects = "/projects",
  Users = "/users",
  Files = "/files",
  Map = "/map",
  MapExp = "/mapExp",
  Admin = "/admin",
  StudyForm = "/studyForm",
  Organization = "/organization",
}

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
  const appState = useAppState();
  const location = useLocation();

  if (appState.Authenticating && !appState.Authenticated) {
    return null;
  }

  if (!appState.Authenticated) {
    return <Navigate to={RoutePaths.Login} state={{ from: location }} replace />;
  }

  return children;
};

const AuthenticatedRoutes: FC = () => {
  return (
    <Routes>
      <Route path={RoutePaths.Home} element={<Home />} />
      <Route path={RoutePaths.UserPage} element={<UserPage />} />
      <Route path={RoutePaths.EditUser} element={<EditUser />} />
      <Route path={RoutePaths.Upload} element={<Upload />} />
      <Route path={RoutePaths.EncryptedContent} element={<EncryptedContent />} />
      <Route path={RoutePaths.Map} element={<Map />} />
      <Route path={RoutePaths.MapExp} element={<MapExp />} />
      <Route path={RoutePaths.Contacts} element={<Contacts />} />
      <Route path={RoutePaths.Users} element={<Users />} />
      <Route path={RoutePaths.Files} element={<Files />} />
    </Routes>
  );
};

export const Routing: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={RoutePaths.Projects} element={<Projects />} />
        <Route path={RoutePaths.Admin} element={<Admin />} />
        <Route path={RoutePaths.StudyForm} element={<Study />} />
        <Route path={RoutePaths.Organization} element={<Organization />} />
        <Route path={RoutePaths.SiteCheckup} element={<SiteCheckup />} />
        <Route path={RoutePaths.StyleGuide} element={<StyleGuide />} />
        <Route path={RoutePaths.SignUp} element={<SignUp />} />
        <Route path={RoutePaths.Login} element={<Login />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <AuthenticatedRoutes />
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  );
};
