import { FC, ReactNode } from "react";

import Header from "../header/header";
import Footer from "../footer/footer";
import ContentWrapper from "../contentWrapper/contentWrapper";
import styles from "./layout.module.scss";
import LeftNav from "components/shared/leftNav/leftNav";

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={styles.baseLayout}>
      <Header />
      <div className={styles.layoutWrapper}>
        <LeftNav />
        <ContentWrapper>{children}</ContentWrapper>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
