import React, { useEffect, useRef } from 'react';
import { Deck } from '@deck.gl/core';
import { Map } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import styles from './map.module.scss';

export interface MapDeckGLProps {
  /** Container className for custom styling */
  className?: string;
  /** Initial center coordinates [longitude, latitude] */
  center?: [number, number];
  /** Initial zoom level */
  zoom?: number;
  /** DeckGL layers to render */
  layers?: any[];
  /** Callback when deck is initialized */
  onDeckInitialized?: (deck: Deck) => void;
  /** Map style URL */
  styleUrl?: string;
  /** Children components to render over the map */
  children?: React.ReactNode;
}

export const MapDeckGLComponent: React.FC<MapDeckGLProps> = ({
  className,
  center = [-106.3468, 56.1304],
  zoom = 10,
  layers = [],
  onDeckInitialized,
  styleUrl = 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
  children
}) => {
  const deckRef = useRef<Deck | null>(null);
  const mapRef = useRef<Map | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    // Initialize the base map
    mapRef.current = new Map({
      container: containerRef.current,
      style: styleUrl,
      interactive: false, // Let DeckGL handle interactions
      center: center,
      zoom: zoom
    });

    // Initialize DeckGL
    deckRef.current = new Deck({
      parent: containerRef.current,
      initialViewState: {
        longitude: center[0],
        latitude: center[1],
        zoom: zoom,
        pitch: 0,
        bearing: 0
      },
      controller: true,
      layers: layers,
      onViewStateChange: ({ viewState }) => {
        // Sync the base map with DeckGL's viewport
        if (mapRef.current) {
          const { longitude, latitude, zoom, bearing, pitch } = viewState;
          mapRef.current.jumpTo({
            center: [longitude, latitude],
            zoom: zoom,
            bearing: bearing,
            pitch: pitch
          });
        }
      }
    });

    if (onDeckInitialized && deckRef.current) {
      onDeckInitialized(deckRef.current);
    }

    return () => {
      if (deckRef.current) {
        deckRef.current.finalize();
        deckRef.current = null;
      }
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  // Update layers when they change
  useEffect(() => {
    if (deckRef.current) {
      deckRef.current.setProps({ layers });
    }
  }, [layers]);

  return (
    <div 
      ref={containerRef} 
      className={`${styles.mapContainer} ${className || ''}`}
    >
      {children}
    </div>
  );
};

// Hook to access the deck instance
export const useDeckGL = () => {
  const deck = useRef<Deck | null>(null);

  const setDeck = (newDeck: Deck) => {
    deck.current = newDeck;
  };

  return {
    deck: deck.current,
    setDeck
  };
};

export default MapDeckGLComponent;
