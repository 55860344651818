interface Item {
  order: number;
}

export const invalidEmail = (email: string) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const isExternalEmail = (email: string) => {
  return !email.toLowerCase().endsWith('@districtcity.com');
};

export const convertYesNoToBool = (selected: string) => {
  return selected === "Yes" ? true : false;
};

export const isUndefined = (input: boolean | undefined) => {
  return input === undefined;
};

export const sortByOrder = (a: Item, b: Item) => { 
  return a.order < b.order ? -1 : a.order > b.order ? 1 : 0; 
};

export const openInNewTab = (url: string | undefined): void => {
  if (!url) return;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const useLightFont = (bgColor: string) => {
  const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return L <= 0.179;
}

/**
 * Extracts all unique colors from an SVG string for a specified CSS property
 * @param svgText - The SVG content as a string
 * @param property - The CSS property to search for (e.g., 'stroke', 'fill')
 * @returns A Set of unique color values found for the property
 * 
 * Example usage:
 * const svgText = '<style>.cls-1{stroke:#000}.cls-2{stroke:#000}</style>';
 * getUniqueColors(svgText, 'stroke') // Returns Set {'#000'}
 */
export const getUniqueColors = (svgText: string, property: string) => {
  // Create regex pattern to match colors for the specified property
  // Matches both hex colors (#fff, #ffffff) and rgb/rgba values
  const colorRegex = new RegExp(`${property}:(#[0-9a-fA-F]{3,6}|rgba?\\([^)]+\\))`, 'g');
  
  // Find all matches in the SVG text
  // Returns empty array if no matches found
  const matches = svgText.match(colorRegex) || [];
  
  // Extract just the color values (removing property name)
  // Convert to Set to remove duplicates
  // Example: ['stroke:#000', 'stroke:#fff'] becomes Set {'#000', '#fff'}
  return new Set(matches.map(match => match.split(':')[1]));
};