import { useEffect, useState, useRef } from "react";
import styles from "./mapExp.module.scss";
import Layout from "components/layout/Layout";
import { Tab } from "components/tab/tab";
import { ReactComponent as GraphIcon } from "assets/images/icons/icon-graph.svg";
import { ReactComponent as MapIcon } from "assets/images/icons/icon-map.svg";
import UseMapHook from "./mapHook";
import MapComponent from "components/map/map";
import maplibregl from 'maplibre-gl';
import { PATTERNS } from 'config/patterns';
import { DropdownOption, Field } from "components/ui/field/field";
import { getUniqueColors } from "utils/commonFunctions";
import { ColorPicker } from 'components/colorPicker/colorPicker';
import type { Feature, Polygon } from 'geojson';
import type { LngLatBoundsLike } from 'maplibre-gl';
import MapDeckGLComponent from "components/map/mapDeckGL";
import { GeoJsonLayer } from '@deck.gl/layers';
import { FillStyleExtension } from '@deck.gl/extensions';

const TORONTO_FEATURE: Feature<Polygon> = {
  type: "Feature" as const,
  properties: {},
  geometry: {
    type: "Polygon" as const,
    coordinates: [[
      [-79.44593738946143, 43.66683180497003],
      [-79.44306917313762, 43.65807499673164],
      [-79.43559916750945, 43.63392984266105],
      [-79.43141120084874, 43.63288118344593],
      [-79.42404092805423, 43.63466597834659],
      [-79.41089624691593, 43.637382167291236],
      [-79.40149897437945, 43.63800136922009],
      [-79.39283013213145, 43.64124468621526],
      [-79.39340854110205, 43.64323175722754],
      [-79.38430688441815, 43.644487231608224],
      [-79.38779551197452, 43.6526447973589],
      [-79.39345933845065, 43.6670476212106],
      [-79.39620785998503, 43.67476040141409],
      [-79.39851698796019, 43.674132673511025],
      [-79.40212532684956, 43.67537875066901],
      [-79.41019764013154, 43.67412411644463],
      [-79.41639288573803, 43.6726666960748],
      [-79.42345382969044, 43.67131269241062],
      [-79.42979491264137, 43.670166760819995],
      [-79.43872868338656, 43.66870665939902],
      [-79.44593738946143, 43.66683180497003]
    ]]
  }
};

const TORONTO_FEATURE_BOUNDS: LngLatBoundsLike = [
  [-79.485603, 43.606897], // Southwest coordinates
  [-79.335571, 43.675222]  // Northeast coordinates
];

// Add type for valid pattern names
type PatternName = 'dots' | 'hatch-1x' | 'hatch-2x' | 'hatch-cross';

export const MapExp = () => {
  const map = useRef<maplibregl.Map | null>(null);

  const { pageTabs, updateLayerList, layerList, onEditClick, onNavTabClick, selectedNavTab, controlPanelTabs, selectedTab, setEditLayer, onTabClick, isLastTabSelected, isFirstTabSelected, onLeftArrowClick, onRightArrowClick, editLayer, editMapLayerData } = UseMapHook();

  const [selectedPattern, setSelectedPattern] = useState<DropdownOption | undefined>(undefined);
  const [patternColor, setPatternColor] = useState<string>("#000000");
  const [patternScale, setPatternScale] = useState<number>(1);
  const [patternRotation, setPatternRotation] = useState<number>(0);

  // Use predefined patterns first to test functionality
  const patterns = ['dots', 'hatch-1x', 'hatch-2x', 'hatch-cross'];

  // Initialize DeckGL layers with default pattern
  const [deckGLLayers, setDeckGLLayers] = useState([
    new GeoJsonLayer({
      id: 'toronto-geojson',
      data: TORONTO_FEATURE,
      filled: true,
      getFillColor: [255, 255, 255, 200],
      stroked: true,
      getLineColor: [0, 136, 136, 255],
      lineWidthMinPixels: 2,
      pickable: true,
    })
  ]);

  // Update the state type
  const [selectedDeckGLPattern, setSelectedDeckGLPattern] = useState<DropdownOption | undefined>(undefined);

  useEffect(() => {
    updateLayerList();
  }, []);

  // Load an image into the map
  const loadImage = (url: string, id: string, map: maplibregl.Map) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        map.addImage(id, img);
        resolve(null);
      };
      img.onerror = reject;
      img.src = url;
    });
  };

  const loadPatternImages = (map: maplibregl.Map) => {
    // Load all patterns
    Promise.all(
      PATTERNS.map(pattern => loadImage(pattern.image, pattern.id, map))
    ).catch(error => console.error('Error loading patterns:', error));
  };

  // Function to create colored and scaled SVG pattern
  const createColoredPattern = (patternId: string, color: string, scale: number, rotation: number) => {
    if (!map.current) return;

    const pattern = PATTERNS.find(p => p.id === patternId);
    if (!pattern) return;

    fetch(pattern.image)
      .then(response => response.text())
      .then(svgText => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgText, 'image/svg+xml');
        const svgElement = doc.documentElement;

        // Get original viewBox values
        const viewBox = svgElement.getAttribute('viewBox')?.split(' ').map(Number) || [0, 0, 24, 24];
        const [x, y, width, height] = viewBox;

        // Apply scaling
        const newWidth = width * scale;
        const newHeight = height * scale;
        svgElement.setAttribute('width', String(newWidth));
        svgElement.setAttribute('height', String(newHeight));

        // Update colors in SVG
        const styleElement = svgElement.querySelector('style');
        if (styleElement) {
          let styleContent = styleElement.textContent || '';

          const uniqueStrokeColors = getUniqueColors(styleContent, 'stroke');
          const uniqueFillColors = getUniqueColors(styleContent, 'fill');

          if (uniqueStrokeColors.size === 1) {
            styleContent = styleContent.replace(
              /stroke:(#[0-9a-fA-F]{3,6}|rgba?\([^)]+\))/g,
              `stroke:${color}`
            );
          }

          if (uniqueFillColors.size === 1) {
            styleContent = styleContent.replace(
              /fill:(#[0-9a-fA-F]{3,6}|rgba?\([^)]+\))/g,
              `fill:${color}`
            );
          }

          styleElement.textContent = styleContent;
        }

        // Convert SVG to string
        const serializer = new XMLSerializer();
        const coloredSvgString = serializer.serializeToString(svgElement);
        const blob = new Blob([coloredSvgString], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        // Create temporary image to load SVG
        const tempImg = new Image();
        tempImg.onload = () => {
          // Calculate the size needed for the rotated pattern
          const angleRad = (rotation * Math.PI) / 180;
          const cosAngle = Math.abs(Math.cos(angleRad));
          const sinAngle = Math.abs(Math.sin(angleRad));

          // Calculate the size that will contain the rotated pattern
          const rotatedWidth = newWidth * cosAngle + newHeight * sinAngle;
          const rotatedHeight = newWidth * sinAngle + newHeight * cosAngle;

          // Create canvas with the exact size needed
          const canvas = document.createElement('canvas');
          canvas.width = rotatedWidth;
          canvas.height = rotatedHeight;
          const ctx = canvas.getContext('2d');

          if (ctx) {
            // Clear the canvas with a transparent background
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Move to center of canvas
            ctx.translate(rotatedWidth / 2, rotatedHeight / 2);
            ctx.rotate(angleRad);

            // Draw the image centered
            ctx.drawImage(
              tempImg,
              -newWidth / 2,
              -newHeight / 2,
              newWidth,
              newHeight
            );

            // Create pattern ID
            const coloredPatternId = `${patternId}-${color.replace('#', '')}-scale${scale}-rotation${rotation}`;

            // Convert canvas to image and add to map
            const patternImage = new Image();
            patternImage.onload = () => {
              if (map.current) {
                map.current.addImage(coloredPatternId, patternImage);
                map.current.setPaintProperty('toronto-fill', 'fill-pattern', coloredPatternId);
                map.current.setBearing(0);
              }
            };
            patternImage.src = canvas.toDataURL();
          }
          URL.revokeObjectURL(url);
        };
        tempImg.src = url;
      })
      .catch(error => console.error('Error creating colored pattern:', error));
  };

  // Function to create pattern atlas and mapping
  const createPatternAtlas = async (patternId: string, color: string, scale: number, rotation: number) => {
    const pattern = PATTERNS.find(p => p.id === patternId);
    if (!pattern) return null;

    // Create a canvas for the atlas
    const canvas = document.createElement('canvas');
    canvas.width = 256;  // Power of 2 for better GPU performance
    canvas.height = 256;
    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    // Load and process SVG
    const svgData = await fetch(pattern.image).then(r => r.text());
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;

    // Apply color changes
    const styleElement = svgElement.querySelector('style');
    if (styleElement) {
      let styleContent = styleElement.textContent || '';
      const uniqueStrokeColors = getUniqueColors(styleContent, 'stroke');
      const uniqueFillColors = getUniqueColors(styleContent, 'fill');

      if (uniqueStrokeColors.size === 1) {
        styleContent = styleContent.replace(
          /stroke:(#[0-9a-fA-F]{3,6}|rgba?\([^)]+\))/g,
          `stroke:${color}`
        );
      }
      if (uniqueFillColors.size === 1) {
        styleContent = styleContent.replace(
          /fill:(#[0-9a-fA-F]{3,6}|rgba?\([^)]+\))/g,
          `fill:${color}`
        );
      }
      styleElement.textContent = styleContent;
    }

    // Convert SVG to image
    const svgBlob = new Blob([svgElement.outerHTML], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svgBlob);

    return new Promise<{ atlas: string; mapping: any }>((resolve) => {
      const img = new Image();
      img.onload = () => {
        // Draw pattern to atlas
        ctx.save();
        ctx.translate(128, 128);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.scale(scale, scale);
        ctx.drawImage(img, -64, -64, 128, 128);
        ctx.restore();

        // Create mapping
        const mapping = {
          [patternId]: {
            x: 0,
            y: 0,
            width: 256,
            height: 256,
            mask: true
          }
        };

        URL.revokeObjectURL(url);
        resolve({
          atlas: canvas.toDataURL('image/png'),
          mapping
        });
      };
      img.src = url;
    });
  };

  // Update the existing updatePattern function to use the new function
  const updatePattern = (patternId: string, color: string, scale: number, rotation: number) => {
    if (map.current) {
      createColoredPattern(patternId, color, scale, rotation);

      const selected = dropdownOptions.find(option => option.value === patternId);
      setSelectedPattern(selected);
      setPatternColor(color);
      setPatternScale(scale);
      setPatternRotation(rotation);
    }
  };

  // Format the patterns for dropdown
  const dropdownOptions = PATTERNS.map(pattern => ({
    value: pattern.id,
    label: pattern.name,
    thumbnail: pattern.image  // Match the boundary dropdown format which uses thumbnail
  }));

  // Format the DeckGL patterns for dropdown with proper typing
  const deckGLPatternOptions: DropdownOption[] = [
    { value: 'dots' as PatternName, label: 'Dots' },
    { value: 'hatch-1x' as PatternName, label: 'Hatch 1x' },
    { value: 'hatch-2x' as PatternName, label: 'Hatch 2x' },
    { value: 'hatch-cross' as PatternName, label: 'Hatch Cross' }
  ];

  // Function to update DeckGL pattern based on dropdown selection and controls
  const updateDeckGLPatternFromDropdown = (option: DropdownOption | null) => {
    if (!option) return;

    setSelectedDeckGLPattern(option);

    // Create new layer
    setDeckGLLayers([
      new GeoJsonLayer({
        id: 'toronto-geojson',
        data: TORONTO_FEATURE,
        filled: true,
        getFillColor: [255, 0, 0, 200],
        stroked: true,
        getLineColor: [0, 136, 136, 255],
        lineWidthMinPixels: 2,
        pickable: true,

        // Pattern properties
        fillPatternMask: true,
        fillPatternAtlas: 'http://localhost:3000/pattern.png',
        fillPatternMapping: 'http://localhost:3000/pattern.json',
        getFillPattern: () => option.value as PatternName,

        // Define extensions
        extensions: [new FillStyleExtension({ pattern: true })]
      })
    ]);
  };

  // Update DeckGL pattern when controls change
  useEffect(() => {
    if (selectedDeckGLPattern) {
      updateDeckGLPatternFromDropdown(selectedDeckGLPattern);
    }
  }, [patternColor, patternScale, patternRotation]);

  const handleMapLoaded = (mapInstance: maplibregl.Map) => {
    // Store map reference
    map.current = mapInstance;

    // Load patterns first
    loadPatternImages(mapInstance);

    // Add a feature polygon around Toronto area
    mapInstance.addSource('toronto', {
      type: 'geojson',
      data: TORONTO_FEATURE
    });

    // Add fill layer with pattern support
    mapInstance.addLayer({
      id: 'toronto-fill',
      type: 'fill',
      source: 'toronto',
      paint: {
        'fill-color': '#ffffff',
      }
    });

    // Add outline layer
    mapInstance.addLayer({
      id: 'toronto-outline',
      type: 'line',
      source: 'toronto',
      paint: {
        'line-color': '#088',
        'line-width': 2
      }
    });

    // Fit map to Toronto bounds
    mapInstance.fitBounds(TORONTO_FEATURE_BOUNDS, {
      padding: { top: 50, bottom: 50, left: 50, right: 50 }
    });
  };

  // Calculate center from bounds
  const center: [number, number] = [
    ((TORONTO_FEATURE_BOUNDS[0] as number[])[0] + (TORONTO_FEATURE_BOUNDS[1] as number[])[0]) / 2,
    ((TORONTO_FEATURE_BOUNDS[0] as number[])[1] + (TORONTO_FEATURE_BOUNDS[1] as number[])[1]) / 2
  ];

  // Calculate zoom level based on bounds
  const longitudeDiff = Math.abs((TORONTO_FEATURE_BOUNDS[1] as number[])[0] - (TORONTO_FEATURE_BOUNDS[0] as number[])[0]);
  const zoom = Math.floor(Math.log2(360 / longitudeDiff)) + 0.5;

  return (
    <Layout>
      <div className={styles.mapPage}>
        <div className={styles.navigationContainer}>
          <Tab icon={<MapIcon />} tabLabel={pageTabs.mapEditting} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.mapEditting || selectedNavTab === ""} />
          <Tab icon={<MapIcon />} tabLabel={pageTabs.ndaAnalysis} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.ndaAnalysis} />
          <Tab icon={<GraphIcon />} tabLabel={pageTabs.areaAnalytics} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.areaAnalytics} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.left}>
            <div className={styles.patternSelector}>
              <Field
                labelName={'SVG Pattern'}
                fieldName={'pattern'}
                fieldType={'selectDropDown'}
                options={dropdownOptions}
                handleOptionChange={(option: DropdownOption | null) =>
                  updatePattern(option?.value as string, patternColor, patternScale, patternRotation)}
                isSearchable={true}
                selectedOption={selectedPattern}
              />
              <ColorPicker
                color={patternColor}
                onChange={(color) => {
                  if (selectedPattern) {
                    updatePattern(selectedPattern.value as string, color, patternScale, patternRotation);
                  }
                }}
                label="Pattern Color"
              />
              <div className={styles.sliderContainer}>
                <label>Pattern Scale ({patternScale.toFixed(2)}x)</label>
                <div className={styles.sliderWrapper}>
                  <span>0.2x</span>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={((patternScale - 0.2) / (5 - 0.2)) * 100}
                    onChange={(event) => {
                      const sliderValue = Number(event.target.value);
                      const newScale = 0.2 + (sliderValue / 100) * (5 - 0.2);
                      if (selectedPattern) {
                        updatePattern(selectedPattern.value as string, patternColor, newScale, patternRotation);
                      }
                    }}
                    className={styles.slider}
                  />
                  <span>5.0x</span>
                </div>
              </div>
              <div className={styles.sliderContainer}>
                <label>Pattern Rotation ({patternRotation}°)</label>
                <div className={styles.sliderWrapper}>
                  <span>0°</span>
                  <input
                    type="range"
                    min="0"
                    max="360"
                    value={patternRotation}
                    onChange={(event) => {
                      const newRotation = Number(event.target.value);
                      if (selectedPattern) {
                        updatePattern(
                          selectedPattern.value as string,
                          patternColor,
                          patternScale,
                          newRotation
                        );
                      }
                    }}
                    className={styles.slider}
                  />
                  <span>360°</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.previewContainer}>
            <div className={styles.mapGrid}>
              <div className={styles.mapWrapper}>
                <MapComponent
                  showExportControl
                  onMapLoaded={handleMapLoaded}
                  className={styles.map}
                />
              </div>
              <div className={styles.mapWrapper}>
                <div className={styles.deckGLControls}>
                  <Field
                    labelName={'DeckGL Pattern'}
                    fieldName={'deckgl-pattern'}
                    fieldType={'selectDropDown'}
                    options={deckGLPatternOptions}
                    handleOptionChange={updateDeckGLPatternFromDropdown}
                    isSearchable={true}
                    selectedOption={selectedDeckGLPattern}
                  />
                  <button 
                    className={styles.clearButton}
                    onClick={() => setDeckGLLayers([])}
                    disabled={deckGLLayers.length === 0}
                  >
                    Clear Pattern
                  </button>
                </div>
                <MapDeckGLComponent
                  center={center}
                  zoom={zoom}
                  className={styles.map}
                  layers={deckGLLayers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
