import React, { useEffect, useState } from "react";
import styles from "./map.module.scss";
import Layout from "components/layout/Layout";
import { MapPanel } from "../../components/mapPanel/mapPanel";
import cn from 'clsx';
import { MapLayer } from "components/mapLayer/mapLayer";
import { MapLayerGroup } from "components/mapLayerGroup/mapLayerGroup";
import DemoMap from "assets/images/demo-map.jpg";
import { Tab } from "components/tab/tab";
import { ReactComponent as GraphIcon } from "assets/images/icons/icon-graph.svg";
import { ReactComponent as MapIcon } from "assets/images/icons/icon-map.svg";
import { MapLayer as MapLayerType, MapLayerGroup as MapLayerGroupType } from "actions/lib/types";
import UseMapHook from "./mapHook";
import { EditMapPanel } from "components/EditMapPanel/editMapPanel";

export const Map = () => {

  const { pageTabs, updateLayerList, layerList, onEditClick, setBorderWidth, borderWidth, opacity, onChangeOpacity, colourList, fillColour, borderColour, onNavTabClick, selectedNavTab, controlPanelTabs, selectedTab, setEditLayer, onTabClick, isLastTabSelected, isFirstTabSelected, onLeftArrowClick, onRightArrowClick, editLayer, editMapLayerData } = UseMapHook();

  useEffect(() => {
    updateLayerList();
  }, []);
  
  const controlPanelContent = layerList.map((layer: MapLayerType | MapLayerGroupType) => {
    if('groupName' in layer) {
      return <MapLayerGroup groupName={layer.groupName} key={`group-${layer.groupName}`}>
        {layer.layers.map(groupLayer  => {
          return (<MapLayer key={groupLayer.layerName} layerName={groupLayer.layerName} boxColor={groupLayer.boxColor} boxBorderColor={groupLayer.boxBorderColor} onEditClick={onEditClick} />);
        })}
      </MapLayerGroup>;
    } else {
      return <MapLayer key={`group-${layer.layerName}`} layerName={layer.layerName} boxColor={layer.boxColor} boxBorderColor={layer.boxBorderColor} onEditClick={onEditClick} />;
    }
  });

  return (
    <Layout>
      <div className={styles.mapPage}>
        <div className={styles.navigationContainer}>
          <Tab icon={<MapIcon />} tabLabel={pageTabs.mapEditting} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.mapEditting || selectedNavTab === ""} />
          <Tab icon={<MapIcon />} tabLabel={pageTabs.ndaAnalysis} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.ndaAnalysis} />
          <Tab icon={<GraphIcon />} tabLabel={pageTabs.areaAnalytics} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.areaAnalytics} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.left}>
            <MapPanel
              containerClass={styles.controlPanelContainer}
              bodyClass={styles.body}
              tabs={controlPanelTabs}
              selectedTab={selectedTab}
              onTabClick={onTabClick}
              isFirstTab={isFirstTabSelected}
              isLastTab={isLastTabSelected}
              onLeftArrowClick={onLeftArrowClick}
              onRightArrowClick={onRightArrowClick}
            >
              {controlPanelContent}
            </MapPanel>
            <MapPanel
              containerClass={cn(styles.elementStyleContainer, styles.dropshadow, editLayer !== '' ? '' : 'hide')}
              hasCloseButton={true}
              onCloseClick={() => setEditLayer('')}
            >
              <EditMapPanel layerName={editMapLayerData.name} boxColour={editMapLayerData.boxColor} boxBorderColour={editMapLayerData.boxBorderColor} />
            </MapPanel>
          </div>
          <div className={styles.previewContainer}>
            <img 
              src={DemoMap}
              className={styles.map}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};