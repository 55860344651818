import { FC, ReactNode } from 'react';

import styles from "./heading.module.scss";

interface HeadingProps {
  children: ReactNode;
}

const Heading: FC<HeadingProps> = ({ children }) => {
  return (
    <h2 className={styles.heading}>{children}</h2>
  );
};

export default Heading;
