import { FC } from 'react';
import { fetchUser, UserProfileData } from 'actions/userActions';
import { useNavigate } from 'react-router-dom';
import { TextField } from 'components/ui/textField/textField';
import styles from './userForm.module.scss';
import { RoutePaths } from 'app/routing/routing';
import { useState, useEffect } from "react";
import { updateUser } from 'actions/userActions';
import { Button } from 'components/ui/button/button';
import { useForm } from 'react-hook-form';

export interface UserFormFields {
  email: string;
  firstName: string;
  lastName: string;
}

export const UserForm: FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<UserFormFields>();
  const [userInfoResponse, setUserInfo] = useState<UserProfileData>();
  let errorMessage = '';

  const fetchData = async () => {
    const userInfoResponse = await fetchUser();
    setUserInfo(userInfoResponse);
  };

  const onSubmit = async (values: UserFormFields) => {
    try {
      if (userInfoResponse){
        await updateUser(userInfoResponse.id, values);
      }
      navigate(RoutePaths.UserPage, {
        state: { saved: true }
      });
    } catch (err: any) {
      errorMessage = `Error saving: ${JSON.stringify(err.original.message)}`;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return ( 
    <form onSubmit={handleSubmit(onSubmit)} >
      <TextField register={register} type="text" name="firstName" placeholder={userInfoResponse? userInfoResponse.firstName : ''} label="First name" className={styles.field} labelClassName={styles.labels} />
      <TextField register={register} type="text" name="lastName" placeholder={userInfoResponse? userInfoResponse.lastName : ''} label="Last name" className={styles.field} labelClassName={styles.labels} />
      <TextField register={register} type="text" name="email" placeholder={userInfoResponse? userInfoResponse.email : ''} label="Email" className={styles.field} labelClassName={styles.labels} errors={errors} validationSchema={{ 
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        }
      }} />
      <Button className={styles.submitButton} type="submit">
            Save
      </Button>
      <p className={styles.errorMessage}>{errorMessage}</p>
    </form>
  );
};