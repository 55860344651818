import React from "react";
import Layout from "components/layout/Layout";
import Title from "components/ui/typograhpy/title/title";
import styles from "./users.module.scss";
import { Header } from "components/shared/header/header";

export const Users = () => {

  return (
    <Layout>
      <div className={styles.projectContainer}>
        <Header
          headerName={"Users"}
        />
      </div>
    </Layout>
  );
};
