import { FC, useState } from 'react';
import { createUser } from 'actions/userActions';
import { useNavigate } from 'react-router-dom';
import styles from './signUpForm.module.scss';
import TextField from 'components/ui/textField/textField';
import { Button } from 'components/ui/button/button';
import { RoutePaths } from 'app/routing/routing';
import { useForm } from 'react-hook-form';

export interface SignUpFormFields {
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const SignUpForm: FC = () => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm<SignUpFormFields>();
  const navigate = useNavigate();

  const [signUpError, setSignUpError] = useState<string | null>(null);

  const onSubmit = async (values: SignUpFormFields) => {
    setSignUpError(null);
    try {
      await createUser(values);
      navigate(RoutePaths.Login);
    } catch (err: any) {
      if (err?.status === 400) {
        setSignUpError('A user for the provided email address already exists');
      } else {
        setSignUpError(`There was an error creating the user. Please try again later.`);
      }
    }
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <TextField register={register} type="email" name="email" placeholder="Email" label="Email" className={styles.field} labelClassName={styles.labels} errors={errors} validationSchema={{ 
        required: "Email is required",
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        }
      }} />
      <TextField register={register} type="password" name="password" placeholder="Password" label="Password" className={styles.field} labelClassName={styles.labels} errors={errors} validationSchema={{ 
        required: "Password is required",
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          message: "Password must have an upper case letter, lower case letter, a number, one of the following special characters @$!%*?& and be at least 8 characters",
        }
      }} />
      <TextField register={register} type="password" name="passwordConfirmation" placeholder="Confirm Password" label="Confirm Password" className={styles.field} labelClassName={styles.labels} errors={errors} validationSchema={{ 
        required: "Confirm Password is required",
        validate: (val: string) => {
          if (watch('password') != val) {
            return "Your passwords do no match";
          }
        }
      }} />
      <Button className={styles.submitButton} type="submit">
            Submit
      </Button>
      {signUpError && <div>{signUpError}</div>}
    </form>
  );
};
