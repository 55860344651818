import { useState, useEffect } from "react";
import { UserProfileData, fetchUser } from "actions/userActions";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths } from "app/routing/routing";
import { Button, ButtonStyle } from "components/ui/button/button";
import Layout from "components/layout/Layout";

export const UserPage = () => {
  const [userInfoResponse, setUserInfo] = useState<UserProfileData>();
  const location = useLocation();
  const navigate = useNavigate();
  const didSave = (location.state as { saved?: boolean })?.saved || false;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const userInfoResponse = await fetchUser();
    setUserInfo(userInfoResponse);
  };

  const onEditButtonClick = () => { 
    navigate(RoutePaths.EditUser);
  };

  let savedMessage = null;

  if (didSave) {
    savedMessage = <p>Profile successfully updated</p>;
  }

  return (
    <Layout>
      <div className="App">
        <header className="App-header">
          <h2>User Page</h2>
          <p>First name: {userInfoResponse? userInfoResponse.firstName : null}</p>
          <p>Last name: {userInfoResponse? userInfoResponse.lastName : null}</p>
          <p>Email: {userInfoResponse?.email}</p>
          <p>User ID: {userInfoResponse?.id}</p>
          <p>Role: {userInfoResponse? userInfoResponse.role : null}</p>
        </header>
        <div>
          <Button type="button" onClick={onEditButtonClick} style={ButtonStyle.Primary}>
            Edit
          </Button>
          {savedMessage}
        </div>
      </div>
    </Layout>
  );
};
