import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, ButtonStyle } from 'components/ui/button/button';
import { DropdownOption, Field } from "components/ui/field/field";
import styles from "./studyProjectForm.module.scss";
import { DocProject, DocProjectType, DocProjectStatus, OrgAuthor } from 'actions/lib/types';
import { useAdminState } from 'context/admin';
import { useEffect } from 'react';
import { updateDocProject, createDocProject } from 'actions/adminActions';

export interface StudyProjectFormFields {
  type_id: number;
  title: string;
  url?: string;
  tags?: string;
  lead_consultant_id?: number;
  prepared_for_id?: number;
  status: string;
  notes?: string;
}

interface StudyProjectFormProps {
  selectedProject: DocProject;
  onCancel?: () => void;
  onSubmitSuccess?: () => void;
}

export const StudyProjectForm: FC<StudyProjectFormProps> = ({ selectedProject, onCancel, onSubmitSuccess }) => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<StudyProjectFormFields>();

  const adminState = useAdminState();
  const {
    orgAuthorList,
    docProjectTypeList
  } = adminState;

  // Create options for dropdowns
  const projectTypeOptions = docProjectTypeList.map((type: DocProjectType) => ({
    value: type.id.toString(),
    label: type.name
  }));

  const orgOptions = orgAuthorList.map((org: OrgAuthor) => ({
    value: org.id.toString(),
    label: org.name
  }));

  const statusOptions = Object.values(DocProjectStatus).map((status: DocProjectStatus) => ({
    value: status,
    label: status
  }));

  const [selectedProjectType, setSelectedProjectType] = useState<DropdownOption | undefined>();
  const [selectedLeadConsultant, setSelectedLeadConsultant] = useState<DropdownOption | undefined>();
  const [selectedPreparedFor, setSelectedPreparedFor] = useState<DropdownOption | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<DropdownOption | undefined>(
    statusOptions.find(s => s.value === selectedProject?.status)
  );
  
  // Load form data when selectedProject changes
  useEffect(() => {
    if (selectedProject) {
      // Find the matching project type option
      const matchingProjectType = projectTypeOptions.find(
        (type) => type.value === selectedProject.type_id?.toString()
      );
      // Set the selected option for the dropdown
      if (matchingProjectType) {
        setSelectedProjectType(matchingProjectType);
      }

      // Find the matching lead consultant option
      const matchingLeadConsultant = orgOptions.find(
        (org) => org.value === selectedProject.lead_consultant_id?.toString()
      );
      if (matchingLeadConsultant) {
        setSelectedLeadConsultant(matchingLeadConsultant);
      }

      // Find the matching prepared for option
      const matchingPreparedFor = orgOptions.find(
        (org) => org.value === selectedProject.prepared_for_id?.toString()
      );
      if (matchingPreparedFor) {
        setSelectedPreparedFor(matchingPreparedFor);
      }

      setValue('type_id', selectedProject.type_id);
      setValue('title', selectedProject.title);
      setValue('url', selectedProject.url || '');
      setValue('tags', selectedProject.tags || '');
      setValue('lead_consultant_id', selectedProject.lead_consultant_id || undefined);
      setValue('prepared_for_id', selectedProject.prepared_for_id || undefined);
      setValue('status', selectedProject.status);
      setValue('notes', selectedProject.notes || '');
    }
  }, [selectedProject, setValue]);

  const handleOptionChange = (event: any, fieldName: string) => {
    setValue(fieldName as keyof StudyProjectFormFields, event.value);
  };

  const handleProjectTypeChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (option) {
      setValue('type_id', parseInt(option.value.toString()));
      setSelectedProjectType(option);
    } else {
      setValue('type_id', 0);
      setSelectedProjectType(undefined);
    }
  };

  const handleLeadConsultantChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (option) {
      setValue('lead_consultant_id', parseInt(option.value.toString()));
      setSelectedLeadConsultant(option);
    } else {
      setValue('lead_consultant_id', 0);
      setSelectedLeadConsultant(undefined);
    }
  };

  const handlePreparedForChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (option) {
      setValue('prepared_for_id', parseInt(option.value.toString()));
      setSelectedPreparedFor(option);
    } else {
      setValue('prepared_for_id', 0);
      setSelectedPreparedFor(undefined);
    }
  };

  const handleStatusChange = (option: DropdownOption | null) => {
    if (option) {
      setValue('status', String(option.value));
      setSelectedStatus(option);
    }
  };

  const onSubmit = async (values: StudyProjectFormFields) => {
    try {
      const docProjectData = {
        type_id: parseInt(values.type_id.toString()),
        title: values.title,
        url: values.url || null,
        tags: values.tags || null,
        lead_consultant_id: values.lead_consultant_id ? parseInt(values.lead_consultant_id.toString()) : null,
        prepared_for_id: values.prepared_for_id ? parseInt(values.prepared_for_id.toString()) : null,
        status: values.status,
        notes: values.notes || null
      };

      if (selectedProject.id) {
        // Update existing project
        await updateDocProject(docProjectData, selectedProject.id);
      } else {
        // Create new project
        await createDocProject(docProjectData);
      }

      // Call onSubmitSuccess to update the list without changing view
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    } catch (error) {
      console.error('Error saving doc project:', error);
      // TODO: Show error message to user
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <label>PROJECT</label>
      <hr className={styles.solid}></hr>

      <Field
        labelName={'Project Type'}
        fieldName={'type_id'}
        fieldType={'selectDropDown'}
        options={projectTypeOptions}
        handleOptionChange={handleProjectTypeChange}
        selectedOption={selectedProjectType}
        isSearchable={true}
        placeholder="Select Project Type"
      />

      <Field
        labelName={'Project Title'}
        fieldName={'title'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Project URL'}
        fieldName={'url'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Tags'}
        fieldName={'tags'}
        fieldType={'textArea'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Lead Consultant'}
        fieldName={'lead_consultant_id'}
        fieldType={'selectDropDown'}
        options={orgOptions}
        handleOptionChange={handleLeadConsultantChange}
        selectedOption={selectedLeadConsultant}
        isSearchable={true}
        placeholder="Select Lead Consultant"
      />

      <Field
        labelName={'Prepared For'}
        fieldName={'prepared_for_id'}
        fieldType={'selectDropDown'}
        options={orgOptions}
        handleOptionChange={handlePreparedForChange}
        selectedOption={selectedPreparedFor}
        isSearchable={true}
        placeholder="Select Prepared For"
      />

      <Field
        labelName={'Project Status'}
        fieldName={'status'}
        fieldType={'selectDropDown'}
        options={statusOptions}
        handleOptionChange={handleStatusChange}
        selectedOption={selectedStatus}
        isSearchable={true}
        placeholder="Select Project Status"
      />

      <Field
        labelName={'Project Notes'}
        fieldName={'notes'}
        fieldType={'textArea'}
        register={register}
        fieldClassName={styles.textField}
      />

      <div className={styles.buttonGroup}>
        <Button
          className={styles.cancelButton}
          type="button"
          style={ButtonStyle.Secondary}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={styles.submitButton}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            // setIsSubmitting(true);
            handleSubmit(onSubmit)(e);
          }}
        >
          Save
        </Button>
      </div>

    </form>
  );
};
