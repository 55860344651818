import React, { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";
import styles from './colorPicker.module.scss';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  label?: string;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ 
  color, 
  onChange,
  label = 'Color' 
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  // Handle click outside to close picker
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.color-picker-container')) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`${styles.container} color-picker-container`}>
      <label className={styles.label}>{label}</label>
      <div
        className={styles.swatch}
        style={{ backgroundColor: color }}
        onClick={() => setShowColorPicker(!showColorPicker)}
      />
      {showColorPicker && (
        <div className={styles.popover}>
          <HexColorPicker
            color={color}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}; 